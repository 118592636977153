body {
	background-color: #002348;
}

/* Helpers perso */
.mr1em {
	margin-right: 1em;
}
.display-none {
	display: none;
}
.textIsBlue {
	color: #2a9bff;
}
.textIsBlue:hover {
	color: #2a9bff;
}

/* Styles spécifiques onboarding */
.info-bubble .info-bubble__chat .chat-body .btn {
	height: 60px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.step-customization .input--file {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* Message */
.site-club__intro .message {
	margin-top: 1em;
	margin-bottom: 1em;
	padding: 24px;
	background-color: #f4f9fe;
	border: 1px solid #e3ecf4;
	color: #002348;
	border-radius: 12px;
}
.message .icon-coment-fb {
	margin-right: 1em;
}

/* Modal */
.modal__wrapper {
	text-align: left;
}

/* Button */

/* Bouton renvoyer le lien magique - modal */
.modal__body .btn .btn--magic-link {
	padding: 16px;
	line-height: 1.5em;
}

/* Button inviter creation/etape4/verification */
button.btn.btn--primary.btn--full-admin-email {
	width: 100%;
}

/* .banner--site-club {
	z-index: 1;
} */

.page-site-club {
	min-height: 470px;
}
/* banner */
@media (min-width: 768px) {
	.banner--site-club .banner__video {
		top: 60%;
	}
}

/* style sur NavLink bloqué */
.link-blocked {
	pointer-events: none;
}

@media (min-width: 1280px) {
	.card-site-club--large .card-site-club__image {
		margin-right: 14px;
	}
	.card-site-club--large {
		padding-left: 14px;
	}
}

.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first ul li {
	padding-left: 24px;
}

.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first ul li:before {
	margin-right: 0;
	margin-left: -24px;
	position: absolute;
}

.is-hidden {
	display: none;
}

/* StepTwo */
.page-site-club .tooltip .icon {
	margin-left: 10px;
}

.icon-linkedin:before {
	content: '\e95c';
}

@media (max-width: 1279px) {
	.page-site-club .tooltip__has-overlay {
		display: none;
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		/* background-color: #999; */
		background-color: rgba(0, 0, 0, 0.5);
		/* opacity: 0.95; */
		z-index: 202;
	}

	.page-site-club .tooltip__has-overlay .tooltip__content.tooltip__content--site-club {
		opacity: unset;
	}

	.page-site-club button.tooltip--site-club {
		position: absolute;
		top: -62px;
		left: -6px;
	}
}

@media (max-width: 767px) {
	.page-site-club button.tooltip--site-club {
		top: -83px;
		left: -6px;
	}
}

/* StepThree */
.step-customization .site-club-container .title-with-tooltip {
	margin-top: 40px;
}

.step-customization .input--file {
	line-height: unset;
	white-space: unset;
	display: flex;
	justify-content: center;
	align-items: center;
}

.step-customization .input--file label {
	width: 75%;
	height: unset;
}

.step-customization .input--file > div {
	position: relative;
}

.step-customization .site-club__sidebar.show-preview {
	overflow: hidden;
}

@media (max-width: 768px) {
	.title-with-tooltip {
		position: relative;
	}
	.site-club-container .step-checking .title-with-tooltip h3 {
		margin-right: 90px;
	}
	.site-club-container .step-checking .title-with-tooltip .btn--edit {
		top: 50%;
		position: absolute;
		right: 0;
		transform: translateY(calc(-50% + 4px));
	}
}

/***************************************************************************************/
/*                               Styles spécifiques Kits comm                          */
/***************************************************************************************/
/* Titres */
h1,
.ft-h1,
h2,
.ft-h2,
h3,
.ft-h3,
h4,
.ft-h4,
h5,
.ft-h5,
h6,
.ft-h6 {
	font-family: 'DinPro';
}

/* HEADER */
.header__mobile {
	text-align: unset;
	padding: 0 24px;
}

/* ! @todo: couleur du menu burger pour tablette ? */
/* @media (min-width: 1080px) {
	.header--transparent {
		border-bottom: none;
	}
} */

/* Footer */

.footer__top hr {
	margin-bottom: unset;
}

.info-bubble {
	right: 40px;
}

/* HelpCenter */
.help-center .site-club-container {
	padding: 80px;
}

.help-center .row {
	padding-top: 25px !important;
}

.help-center .faq-theme {
	cursor: pointer;
}

.help-center .faq-theme.btn--primary {
	white-space: unset;
	font-weight: bold;
	background-color: #023062;
}

.help-center .list-competition .btn--icon {
	height: 32px;
	line-height: 28px;
}

.help-center .faq-theme {
	text-align: left;
	height: auto;
	line-height: 1.4em;
	padding: 10px 15px;
}

/* Bulle - CreationAssistant */
.info-bubble .info-bubble__widget .more p {
	line-height: 64px;
}

@media (max-width: 767px) {
	.info-bubble .info-bubble__widget .more {
		display: none;
	}
}

@media (min-width: 768px) {
	.page-kits-comm .info-bubble {
		margin-bottom: unset;
		min-height: unset;
	}

	.info-bubble.kits-comm-pages .info-bubble__chat,
	.editor-body .info-bubble {
		bottom: 396px;
		right: 0;
		width: 40%;
		margin-left: 60%;
	}

	.info-bubble.is-open.kits-comm-pages .info-bubble__widget {
		bottom: 316px;
	}
}

@media (min-width: 1080px) {
	.info-bubble.kits-comm-pages .info-bubble__chat,
	.editor-body .info-bubble {
		width: 30%;
		margin-left: 70%;
	}
}

@media (min-width: 1281px) {
	.info-bubble.kits-comm-pages .info-bubble__chat,
	.editor-body .info-bubble {
		width: 25%;
		margin-left: 75%;
	}
}

/* COOKIE BANNER */
div.cookie-container {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 16px;
	padding-top: 30px;
	z-index: 10;
}

div.box.cookie {
	position: relative;
	z-index: 0;
	left: unset;
	right: unset;
	top: unset;
	bottom: unset;
	padding: 24px;
	overflow: visible;
}

button.cookie-close {
	position: absolute;
	top: -8px;
	right: 10px;
	user-select: none;
	color: #000;
	width: unset;
	box-shadow: none;
}

button.cookie-close:hover {
	color: #000;
}

@media (min-width: 768px) {
	.cookie {
		padding-right: 10px;
		display: flex;
	}
	.cookie p {
		margin: auto;
	}
	.cookie .btn-container {
		margin-left: 80px;
	}
	.cookie .btn {
		position: relative;
		margin: 0.5rem;
		top: 0;
		right: 0;
		width: 200px;
		padding: 0 32px;
	}
}

/* Modal */
.site-club-creation-comite .modal__body p:first-child {
	padding-right: 0;
}

.modal__wrapper .input {
	text-align: left;
}

.modal__wrapper h4 {
	opacity: 0.64;
}

/* ChooseKitFormats Modal */
.modal--kit .modal__wrapper {
	display: flex;
}

.modal--kit .modal__body {
	padding: 0;
}

.modal--kit__preview {
	display: none;
	text-align: center;
}

.modal--kit__preview p {
	font-size: 16px;
	line-height: 24px;
	color: #7d93a8;
	font-weight: 500;
}

.modal--kit .error {
	color: #e7221f;
	border: unset;
	box-shadow: unset;
}

@media (min-width: 1280px) {
	.modal--kit__preview {
		display: flex;
		padding: 32px;
		width: 380px;
		background-color: #f0f2f5;
		flex: 1;
		align-items: center;
	}
}

.modal-content.modal--kit .modal__wrapper {
	padding-top: unset;
	padding-left: unset;
	padding-right: unset;
	padding-bottom: unset;
}

.modal--kit__content {
	padding: 48px 32px 40px 32px;
	background-color: white;
}

.modal--kit h3 {
	color: #292929;
}

.modal .format-list {
	display: flex;
	max-width: 650px;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
}

.modal__wrapper.modal__wrapper--delete .modal--kit__preview {
	max-width: 328px;
}

.modal__wrapper.modal__wrapper--delete .modal--kit__content {
	max-width: 444px;
}

.modal__wrapper.modal__wrapper--delete h3 {
	color: #ed362f;
}

.modal__wrapper.modal__wrapper--delete .btn--red {
	background-color: #f2362f;
}

.modal__wrapper.modal__wrapper--delete .btn--white {
	border: unset;
}

/* SingleFormatCard */
.card-single-format {
	display: flex;
	align-items: center;
	border: 1px solid rgba(2, 48, 98, 0.1);
	border-radius: 4px;
	padding: 0 14px;
	width: 190px;
	height: 56px;
	margin-bottom: 16px;
	opacity: 0.79;
	box-sizing: border-box;
	font-weight: 500;
	cursor: pointer;
}

.card-single-format.is-selected {
	background: rgba(42, 155, 255, 0.1);
	border: 1px solid #2a9bff;
}

.card-single-format:not(:nth-child(3)) {
	margin-right: 16px;
}

.card-single-format__placeholder {
	margin-right: 8px;
	width: 32px;
}

.card-single-format__placeholder > div {
	background-color: #ebebeb;
	border-radius: 2px;
}

.card-single-format.is-selected .card-single-format__placeholder > div {
	background-color: #2a9bff;
	opacity: 0.3;
}

.card-single-format p {
	font-size: 13px;
	line-height: 16px;
	margin: unset;
}

.card-single-format.is-selected p {
	color: #2a9bff;
}

.card-single-format span {
	font-size: 12px;
	line-height: 14px;
}

/* BANNER : BillboardBanner ou KitBanner */
@media (max-width: 767px) {
	.banner.banner--site-club {
		min-height: 300px;
	}

	.banner .ft-h2 {
		margin-top: 32px;
		margin-bottom: 16px;
		line-height: 34px;
	}

	.banner--site-club .banner__pres p {
		padding-bottom: 15px;
	}
}

@media (min-width: 768px) {
	.banner .ft-h2 {
		font-size: 40px;
		line-height: 40px;
	}
}

@media (min-width: 1280px) and (max-width: 1679px) {
	.banner--site-club > .container {
		margin-top: unset;
	}
	.banner--site-club.banner--site-club-index .ft-h2 {
		margin-left: unset;
	}
}

.banner--site-club .banner__pres {
	float: unset;
}

.banner--site-club .banner__pres p,
.banner--site-club.banner--kits-comm p {
	font-size: 16px;
	line-height: 24px;
}

.banner--site-club > .container.isSmall,
.site-club__content > .container {
	padding: 0 24px;
}

.banner--site-club-index {
	margin-bottom: unset;
}

/* BILLBOARD */
/* billboard banner */

/* billboard page - tous les blocs */
.block-site-club__content .container.isSmall {
	padding: 64px 24px;
}

.block-site-club.block-site-club--index .block-site-club__content h3 {
	font-size: 20px;
	line-height: 24px;
}

@media (min-width: 768px) {
	.block-site-club__content.block-site-club__content .container {
		padding: 80px 52px 126px 40px;
	}
}

@media (min-width: 1280px) {
	.block-site-club__content.block-site-club__content .container {
		padding: 0 52px 126px 40px;
	}
}

/* billboard page - blocs impairs */
.block-site-club__content.block-site-club__content--first h2,
.block-site-club__content.block-site-club__content--first h3 {
	color: #023062;
}

.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first h3 {
	border-left: 1px #023062 solid;
	padding-left: 16px;
}

.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first p {
	margin-bottom: 0;
	font-size: 16px;
	color: #7d93a8;
}

.block-site-club.block-site-club--index
	.block-site-club__content.block-site-club__content--first
	.block-site-club--services
	div {
	/* border-left: 1px #023062 solid; */
	border-left: 1px rgb(2, 48, 98, 0.1) solid;
	padding-left: 16px;
	padding-bottom: 12px;
}

.block-site-club.block-site-club--index
	.block-site-club__content.block-site-club__content--first
	.block-site-club--services
	div:last-child {
	padding-bottom: unset;
	padding-top: 12px;
}

.block-site-club.block-site-club--index
	.block-site-club__content.block-site-club__content--first
	.site-club__content__img
	div {
	text-align: center;
}

.block-site-club.block-site-club--index
	.block-site-club__content.block-site-club__content--first
	.site-club__content__img
	div
	.btn {
	margin-top: 228px;
}

@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first h3 {
		margin-top: unset;
	}

	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first p {
		padding-top: 9px;
	}
}

@media screen and (min-width: 1280px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first {
		margin-bottom: unset;
	}

	.block-site-club__content.block-site-club__content--first .container:first-child {
		padding-top: 120px;
		padding-right: 64px;
	}

	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first h3 {
		margin-top: 0;
	}

	.block-site-club.block-site-club--index
		.block-site-club__content.block-site-club__content--first
		.site-club__content__img {
		height: unset;
	}
}

/* billboard page - blocs pairs */
.block-site-club__content--second .container {
	width: 100%;
	height: 100%;
	background: no-repeat center center;
	background-size: cover;
	color: white;
}

.block-site-club__content.block-site-club__content--second .container.isSmall {
	padding-bottom: 0;
}

.block-site-club__content.block-site-club__content--second .site-club__content__img img {
	width: 100%;
}

.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second p {
	margin-bottom: 22px;
}

.block-site-club__content.block-site-club__content--second ul li {
	font-size: 16px;
	line-height: 19px;
	color: white;
}

.block-site-club__content.block-site-club__content--second ul li:not(:last-child) {
	margin-bottom: 17px;
}

.block-site-club__content.block-site-club__content--second ul li:before {
	content: '';
	display: inline-block;
	width: 16px;
	height: 16px;
	background-image: url('../img/rectangle.png');
	background-position: center;
	background-size: cover;
	margin-right: 8px;
}

@media screen and (min-width: 768px) {
	.block-site-club__content.block-site-club__content--second {
		overflow: hidden;
	}

	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second {
		height: 504px;
	}

	.block-site-club__content.block-site-club__content--second .site-club__content__img div {
		content: '';
		max-width: 640px;
		height: 504px;
		background-position: center;
		background-size: cover;
	}

	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second p {
		margin-bottom: 18px;
	}

	.block-site-club.block-site-club--index
		.block-site-club__content.block-site-club__content--second
		.row
		> div:last-child
		> div {
		position: relative;
		top: unset;
		transform: unset;
		padding: unset;
	}
}

@media screen and (min-width: 1280px) {
	.block-site-club.block-site-club--index
		.block-site-club__content.block-site-club__content--second
		.site-club__content__img
		img {
		position: relative;
		bottom: unset;
		margin: auto;
		max-width: unset;
		overflow: hidden;
	}

	.block-site-club.block-site-club--index
		.block-site-club__content.block-site-club__content--second
		.row
		> div:last-child
		> div {
		transform: unset;
		left: unset;
	}

	.block-site-club.block-site-club--index
		.block-site-club__content.block-site-club__content--second
		.row
		> div:last-child
		> div {
		position: relative;
		top: unset;
		transform: unset;
		/* padding: 74px 70px; */
		width: 80%;
		padding-top: 74px;
		margin: 0 auto;
	}
}

/* block-site-club__header */
.block-site-club.block-site-club--index .block-site-club__header .info-bubble {
	min-height: unset;
}
.block-site-club.block-site-club--index .block-site-club__header .info-bubble .info-bubble__widget {
	top: -70px;
}

@media screen and (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__header > .container {
		position: relative;
		top: unset;
		transform: unset;
	}

	.block-site-club.block-site-club--index .block-site-club__header .icon-coment-fb {
		right: unset;
	}
}

@media screen and (min-width: 1280px) {
	.block-site-club.block-site-club--index .block-site-club__header {
		padding: 0 176px;
	}
}

/* Cookies */
div.box.cookie {
	padding-top: 48px !important;
}

div.cookie-container {
	position: fixed;
	padding: 16px;
	padding-top: 30px;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;
}

div.box.cookie {
	position: relative;
	z-index: 0;
	left: unset;
	right: unset;
	top: unset;
	bottom: unset;
	padding: 24px;
	overflow: visible;
}

.cookie-container button.cookie-close {
	position: absolute;
	top: -8px;
	right: 10px;
	user-select: none;
	color: #1a1a1a;
	width: unset;
	box-shadow: none;
}

.accept-cookie-container p {
	display: inline-block;
	font-weight: bold;
	font-size: large;
	margin-right: 2em;
	padding: 16px 0;
	line-height: 24px;
	vertical-align: top;
}

.accept-cookie-container.black-text p {
	color: black;
}

@media (min-width: 768px) {
	div.cookie {
		padding-right: 10px;
		display: flex;
	}

	div.cookie .btn-container {
		margin-left: 80px;
	}

	div.cookie p {
		margin: auto;
	}
}

/* .site-club */
@media (max-width: 767px) {
	.site-club-container .site-club__content.admin-selection {
		padding-bottom: unset;
	}

	.site-club-container .site-club__intro h2 {
		font-size: 24px;
		line-height: 28px;
	}
}

@media (min-width: 1280px) {
	.site-club-container .site-club__content {
		padding: 82px 64px;
	}
}

.site-club-container .site-club__intro p,
.card--site-choice p {
	font-size: 16px;
	line-height: 24px;
}

.site-club__sidebar {
	display: none;
}

@media (min-width: 768px) {
	.site-club__sidebar {
		display: block;
	}
}

/* Partie /kits/ */
/* KitBanner */
.banner.banner--kits-comm h1 {
	margin-top: unset;
}

.banner.banner--kits-comm .btn span {
	font-size: 14px;
	color: #aad6fd;
	margin-right: 10px;
}

@media (min-width: 768px) and (max-width: 1079px) {
	.banner--site-club.banner--kits-comm {
		height: unset;
	}
}

@media (min-width: 1280px) {
	.banner.banner--kits-comm h1 {
		margin-top: 40px;
	}
}

.banner--kits-comm .container.is-flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.js-menuPlus li a{
	color:#000;
}

/* Menu */
@media (min-width: 1080px) {
	.menu {
		color: white;
	}

	.menu--background-color .menu {
		background-color: rgba(0, 35, 72, 0.7);
	}

	.menu h1 {
		text-transform: uppercase;
		font-size: 12px;
		line-height: 16px;
		position: absolute;
		left: 120px;
		top: 38px;
	}

	.menu__link li {
		font-size: 16px;
	}

	.menu__right {
		line-height: 2;
	}

	.menu__right .accordion-trigger,
	.menu__right .accordion-panel.accordion-panel--kits-comm {
		width: 255px;
	}

	.menu__right a {
		display: block;
	}

	.accordion-trigger {
		padding: 0 16px;
		transition: opacity ease 0.5s, max-height ease 0.5s, visibility ease 0.5s;
	}

	.accordion-trigger::after {
		line-height: unset;
	}

	.accordion-trigger.is-open {
		background-color: rgba(0, 0, 0, 0.4);
	}

	.accordion-trigger.is-open::after {
		content: '\e929';
	}

	.menu__right .accordion-trigger--kits-comm {
		line-height: 104px;
	}

	.menu__right .accordion-panel.accordion-panel--kits-comm {
		top: auto;
	}

	.accordion-trigger.accordion-trigger--kits-comm::after {
		margin-left: 8px;
	}

	.accordion-panel.accordion-panel--kits-comm {
		position: absolute;
		top: 30px;
		width: 100%;
		margin-right: 40px;
		background-color: rgba(0, 0, 0, 0.4);
		padding: 16px 0 16px 16px;
	}

	.header--transparent .menu {
		display: flex;
		justify-content: space-between;
	}

	.header--transparent .menu__link > li:hover > a {
		box-shadow: unset;
	}

	.header--transparent .menu__link.menu__link--only {
		color: black;
	}

	.header--transparent .menu__link:not(.menu__link--only) > li:hover > a,
	.header--transparent .menu__link:not(.menu__link--only) > li.is-active > a {
		box-shadow: 0 -4px 0 #f2362f inset;
	}

	.menu__right {
		padding-right: unset;
	}
}

@media (min-width: 1672px) {
	.menu--background-color .menu {
		max-width: unset;
		padding: 0 100px;
	}

	.menu h1 {
		padding-left: 100px;
	}
}

/* fragment Button */
.icon--before {
	margin-right: 6px;
}

.icon--after {
	margin-left: 6px;
}

/* page-kits-comm */
@media (min-width: 768px) {
	.site-club-container .container {
		padding: 0 40px;
	}

	.page-kits-comm .site-club-container .row {
		margin-left: unset;
	}

	.page-kits-comm .site-club-container .col {
		padding-left: unset;
	}

	.page-kits-comm .site-club-container > div > .row:first-child,
	.page-kits-comm .site-club-container > .row:first-child {
		padding-top: 80px;
	}

	.page-kits-comm .site-club-container > div > .row:last-child {
		padding-bottom: 80px;
	}

	.page-kits-comm .kit-row__content,
	.page-kits-comm .my-kit-row__content {
		overflow-x: scroll;
		/* overflow-y: hidden; */
		display: flex;
		justify-content: flex-start;
		margin-left: -16px;
		margin-bottom: -150px;
		height: 400px;
	}

	.page-site-club.page-kits-comm > .container {
		max-width: 1280px;
	}
}

@media (min-width: 1280px) {
	.page-site-club.page-kits-comm > .container {
		padding-left: unset;
		padding-right: unset;
		max-width: 1440px;
	}

	.page-kits-comm .site-club-container .row {
		margin-left: unset;
	}

	.page-kits-comm .site-club-container .col {
		padding-left: unset;
	}
}

@media (min-width: 1440px) {
	.page-site-club.page-kits-comm > .container {
		padding-left: 60px;
		padding-right: 60px;
	}

	.page-kits-comm .site-club-container .col {
		padding-left: 16px;
	}

	.page-kits-comm .kit-row__content,
	.page-kits-comm .my-kit-row__content {
		overflow-x: hidden;
	}
}

.empty-kit-list {
	height: 180px;
	width: 100%;
	background-position: center;
	background-size: cover;
	padding-top: 46px;
	text-align: center;
}

@media (min-width: 1280px) {
	.empty-kit-list {
		margin-left: 16px;
	}
}

.empty-kit-list p,
.empty-kit-list a {
	font-size: 16px;
	line-height: 24px;
}

.empty-kit-list p {
	opacity: 0.5;
}

.empty-kit-list p:first-child {
	font-weight: bold;
	font-size: 20px;
	opacity: 1;
}

.empty-kit-list .icon-chevron-down {
	font-size: 8px;
}

.page-kits-comm .container.kit-row__intro,
.page-kits-comm .container.my-kit-row__intro {
	height: auto;
	display: flex;
	align-items: center;
}

.page-kits-comm .kits__link {
	color: #2a9bff;
	text-align: right;
	white-space: nowrap;
}

/* MyKitsPAge */

@media (min-width: 768px) {
	.container .kits-grid__content {
		display: grid;
		grid-template-columns: 50% 50%;
	}
}

@media (min-width: 1010px) {
	.container .kits-grid__content {
		grid-template-columns: 33% 33% 33%;
	}
}

@media (min-width: 1340px) {
	.container .kits-grid__content {
		grid-template-columns: 25% 25% 25% 25%;
	}
}

/* KitsTemplatesPage */
.input--search.input--search--kits:before {
	font-size: 18px;
	line-height: 18px;
	position: unset;
	margin-top: unset;
	margin-right: 43px;
}

.kit-filter {
	display: flex;
	align-items: center;
	padding: 0 40px;
}

.kit-filter li {
	/* width: 152px; */
	text-align: center;
}

.billboard__links a {
	padding: unset;
}

.billboard__links li {
	font-family: 'DINPro';
	font-weight: bold;
	font-size: 13px;
	line-height: 20px;
	display: block;
}

.kit-filter__link,
.billboard__links a {
	padding: 20px 16px;
	font-family: 'DINPro';
	font-weight: bold;
	font-size: 13px;
	line-height: 20px;
	text-transform: uppercase;
	color: #7d93a8;
	cursor: pointer;
}

.kit-filter__link:hover,
.kit-filter__link.is-selected,
.billboard__links a:hover {
	color: #2a9bff;
	border-bottom: 2px solid #2a9bff;
}

.billboard__plus .billboard__sub.accordion-panel {
	margin-top: 3px;
	display: none;
}

.billboard__plus:hover .billboard__sub.accordion-panel {
	display: block;
}

.billboard__plus .accordion-trigger::after {
	float: unset;
	vertical-align: middle;
	margin-left: 4px;
}

/* kitCard */
.card-kits-comm--container {
	height: 525px;
}

.kit-row:last-child .card-kits-comm--container {
	height: 345px;
}

/* todo => réussir à mettre un height d'environ 245px sur les cartes de la dernière ligne de la grid */
@media (min-width: 768px) {
	.card-kits-comm--container {
		margin-top: -210px;
	}

	.card-kits-comm--container:nth-child(1),
	.card-kits-comm--container:nth-child(2) {
		margin-top: unset;
	}

	/* https://www.odevie.org/integration-html-css/cibler-les-premieres-et-dernieres-lignes-dans-une-grille-css/ */
	.card-kits-comm--container:nth-child(2n + 1):nth-last-child(-n + 2),
	.card-kits-comm--container:nth-child(2n + 1):nth-last-child(-n + 2) ~ .card-kits-comm--container {
		margin-bottom: -210px;
	}

	.kit-row:last-child .card-kits-comm--container:nth-child(2n + 1):nth-last-child(-n + 2),
	.kit-row:last-child .card-kits-comm--container:nth-child(2n + 1):nth-last-child(-n + 2) ~ .card-kits-comm--container {
		margin-bottom: -30px;
	}
}

@media (min-width: 1010px) {
	.card-kits-comm--container:nth-child(3) {
		margin-top: unset;
	}

	/* https://www.odevie.org/integration-html-css/cibler-les-premieres-et-dernieres-lignes-dans-une-grille-css/ */
	.card-kits-comm--container:nth-child(3n + 1):nth-last-child(-n + 3),
	.card-kits-comm--container:nth-child(3n + 1):nth-last-child(-n + 3) ~ .card-kits-comm--container {
		margin-bottom: -210px;
	}

	.kit-row:last-child .card-kits-comm--container:nth-child(3n + 1):nth-last-child(-n + 3),
	.kit-row:last-child .card-kits-comm--container:nth-child(3n + 1):nth-last-child(-n + 3) ~ .card-kits-comm--container {
		margin-bottom: -30px;
	}
}

@media (min-width: 1340px) {
	.card-kits-comm--container:nth-child(4) {
		margin-top: unset;
	}

	/* https://www.odevie.org/integration-html-css/cibler-les-premieres-et-dernieres-lignes-dans-une-grille-css/ */
	.card-kits-comm--container:nth-child(4n + 1):nth-last-child(-n + 4),
	.card-kits-comm--container:nth-child(4n + 1):nth-last-child(-n + 4) ~ .card-kits-comm--container {
		margin-bottom: -210px;
	}

	.kit-row:last-child .card-kits-comm--container:nth-child(4n + 1):nth-last-child(-n + 4),
	.kit-row:last-child .card-kits-comm--container:nth-child(4n + 1):nth-last-child(-n + 4) ~ .card-kits-comm--container {
		margin-bottom: -30px;
	}
}

.card-kits-comm {
	width: 309px;
	padding: 16px;
	margin: 0 auto;
}

.card-kits-comm:hover {
	cursor: pointer;
}

.card-kits-comm.is-selected {
	box-shadow: 0px 0px 16px rgba(0, 35, 72, 0.08);
	border-radius: 10px;
	background-color: white;
}

.card-kits-comm .card-thumbnail {
	position: relative;
	border-radius: 8px;
	width: 277px;
	height: 180px;
	overflow: hidden;
	background-color: #f0f2f5;
	padding: 20px 16px;
}

.card-kits-comm .card-thumbnail.card-empty {
	border: 1px solid rgba(2, 48, 98, 0.1);
	background-color: white;
}

.card-kits-comm .card-thumbnail .delete-kit-tag {
	display: none;
	position: absolute;
	top: 8px;
	right: 8px;
	background-color: #f2362f;
	border-radius: 6px;
	padding: 8px 12px;
	color: #fff;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
}

.card-kits-comm.is-selected .card-thumbnail .delete-kit-tag {
	display: block;
}

.plus {
	font-size: 24px;
	font-weight: bold;
	color: #7d93a8;
	margin-top: 54px;
}

.card-kits-comm .card-thumbnail:nth-child(4) {
	margin-right: 0;
}

.card-kits-comm .card-footer p {
	margin: 0;
}

.card-kits-comm .card-footer .figcaption {
	font-size: 12px;
	font-weight: bold;
	line-height: 20px;
	opacity: 0.64;
}

.card-kits-comm .card-footer .title {
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
}

.card-kits-comm .card-footer .format-tag {
	margin: 2px 8px 2px 0;
	border-radius: 4px;
	padding: 4px 6px;
	background-color: #f0f2f5;
	font-weight: bold;
	font-size: 12px;
	color: #7d93a8;
	display: inline-flex;
}

.card-kits-comm .card-options {
	display: none;
}

.card-kits-comm.is-selected .card-options {
	display: block;
}

.card-kits-comm.is-selected .card-options .btn--white {
	box-shadow: unset;
	border: unset;
}

/* specific à Download button (sur MyKitCard et EditorHeader) */
.accordion-panel--download-dropdown {
	position: absolute;
	border: 1px solid #f0f2f5;
	box-sizing: border-box;
	box-shadow: 0px 3px 8px rgba(51, 51, 51, 0.08);
	border-radius: 4px;
	background-color: white;
}

.accordion-panel--download-dropdown:not(.is-hidden) {
	z-index: 101;
	margin-top: 8px;
	/*right: 40px;*/
}

.accordion-panel--download-dropdown li {
	width: 277px;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #7d93a8;
}

.accordion-panel--download-dropdown li:not(:last-child) {
	border-bottom: 1px solid #f0f2f5;
}

.accordion-panel--download-dropdown li a {
	display: block;
	height: 48px;
	padding: 16px;
}
/* EDITOR */
/* HeaderEditor */
.editor-header,
.editor-header__left,
.editor-header__right {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.editor-header {
	padding: 16px 40px;
	background: #fff;
}

.editor-header .link-chevron i {
	font-size: 14px;
	color: #002348;
}

.editor-header__kit-title {
	margin: unset;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	color: #002348;
}

.editor-header__edit {
	cursor: pointer;
}

.editor-header__edit::after {
	content: '';
	display: inline-block;
	width: 11px;
	height: 11px;
	background-image: url('../img/icon-edit.png');
	background-position: center;
	background-size: cover;
	margin-left: 8px;
}

.editor-header__right p {
	margin: unset;
	margin-right: 16px;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	color: #7d93a8;
}

.editor-header__right p.is-saved {
	color: #2ecc71;
}

.editor-header__right p.is-saved::before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 5px;
	width: 5px;
	border-radius: 50%;
	background-color: #2ecc71;
	margin-right: 6px;
}

.editor-header__right p.to-save {
	display: flex;
	color: #f2362f;
	line-height: 40px;
}

/* .editor-header__right p.to-save::after */
.editor-header__right p.to-save img {
	/* content: '';
	display: inline-block; */
	width: 40px;
	height: 40px;
	/* background-image: url('../img/icon-save.svg'); */
	/* background-position: center; */
	/* background-size: cover; */
	margin-left: 8px;
	cursor: pointer;
}

.editor-header__right p.to-save::before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 5px;
	width: 5px;
	border-radius: 50%;
	color: #f2362f;
	margin-right: 6px;
}

.editor-header__right p.save-in-progress {
	color: #ff6600;
}

.editor-header__right p.save-in-progress::before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 5px;
	width: 5px;
	border-radius: 50%;
	color: #ff6600;
	margin-right: 6px;
}

.editor-header__right ul {
	margin: unset;
}

/* RenameMyKitModal */
.rename-my-kit .modal__wrapper h3 {
	color: #292929;
}

.rename-my-kit .modal__wrapper input {
	min-width: 432px;
	font-weight: 500;
	line-height: 20px;
}

/* KitCreationInProgressModal */
.kit-creation-in-progress .modal__body {
	width: 480px;
	height: 460px;
	justify-content: center;
}

.kit-creation-in-progress p {
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: #292929;
}

/* https://stackoverflow.com/questions/22531861/ring-shaped-process-spinner-with-fading-gradient-effect-around-the-ring */
@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
/* Tentative de rotation counter-clockwise */
/* @keyframes rotate {
	from {
		transform: rotate(360deg);
	}
	to {
		transform: rotate(0deg);
	}
} */

.spinner {
	animation: rotate 1.5s linear infinite;
	background: #2a9bff;
	border-radius: 50%;
	height: 48px;
	width: 48px;
	position: relative;
}

.spinner::before,
.spinner::after {
	content: '';
	position: absolute;
}

.spinner::before {
	border-radius: 50%;
	background: linear-gradient(0deg, hsla(0, 0%, 100%, 1) 50%, hsla(0, 0%, 100%, 0.9) 100%) 0% 0%,
		linear-gradient(90deg, hsla(0, 0%, 100%, 0.9) 0%, hsla(0, 0%, 100%, 0.6) 100%) 100% 0%,
		linear-gradient(180deg, hsla(0, 0%, 100%, 0.6) 0%, hsla(0, 0%, 100%, 0.3) 100%) 100% 100%,
		linear-gradient(360deg, hsla(0, 0%, 100%, 0.3) 0%, hsla(0, 0%, 100%, 0) 100%) 0% 100%;
	/* Tentative de rotation counter-clockwise */
	/* background: linear-gradient(0deg, hsla(0, 0%, 100%, 0) 100%, hsla(0, 0%, 100%, 0.3) 0%) 100% 0%,
		linear-gradient(90deg, hsla(0, 0%, 100%, 0.3) 100%, hsla(0, 0%, 100%, 0.6) 0%) 100% 100%,
		linear-gradient(180deg, hsla(0, 0%, 100%, 0.6) 100%, hsla(0, 0%, 100%, 0.9) 0%) 0% 100%,
		linear-gradient(360deg, hsla(0, 0%, 100%, 0.9) 100%, hsla(0, 0%, 100%, 1) 50%) 0% 0%; */

	background-repeat: no-repeat;
	background-size: 50% 50%;
	top: -1px;
	bottom: -1px;
	left: -1px;
	right: -1px;
}

.spinner::after {
	background: white;
	border-radius: 50%;
	top: 10%;
	bottom: 10%;
	left: 10%;
	right: 10%;
}

/* EditorBody */
.editor-body {
	display: flex;
	height: calc(100vh - 80px);
	/* overflow: auto; */
	background: #e5e5e5;
}

.editor-body__aside {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
	border-right: 1px solid #023062;
}

.editor-body__aside__formats {
	padding: 16px;
}

.editor-body__aside ul {
	height: calc(100vh - 140px);
	overflow: scroll;
}

.editor-body__aside ul,
.editor-body__aside li {
	padding: 16px;
}

.editor-body__aside li.is-selected {
	position: relative;
	background-color: rgba(2, 48, 98, 0.08);
	border-radius: 8px;
}

.editor-body__aside li.is-selected .js-deleteModal {
	position: absolute;
	right: 12px;
	font-size: 10px;
	color: rgba(0, 0, 0, 0.2);
}

.icon-close {
	cursor: pointer;
}
/* .editor-body__aside .editor-body__format-image {
	width: 100%;
	height: 68px;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
} */

.editor-body__format-name {
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	color: #7d93a8;
}

.editor-body__aside button {
	border-top: 1px solid #023062;
	padding: 15px;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #2a9bff;
	cursor: pointer;
}

.editor-body__aside button span {
	margin-right: 10px;
}

.editor-body__section {
	height: 100%;
	flex: 5;
	overflow: auto;
}

.editor-body .info-bubble {
	bottom: 0px;
	margin-bottom: unset;
}

.editor-body .info-bubble.is-open.kits-comm-pages .info-bubble__widget {
	bottom: 48px;
}

.editor-body .info-bubble.kits-comm-pages .info-bubble__chat {
	bottom: 128px;
}

/* RemoveKitFormatModalContent */
.modal__wrapper.modal__wrapper--delete.modal__wrapper--remove-format .modal--kit__content {
	max-width: 460px;
}

/* incompatibleBrowserWarning */
.warning--kits {
	position: fixed;
	height: 100vh;
	width: 100vw;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #292929;
	z-index: 11;
	background: transparent;
}

.warning__wrapper--kits {
	position: fixed;
	top: 32px;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	max-width: 792px;
	box-shadow: 0px 2px 4px rgba(0, 35, 72, 0.08);
	border-radius: 4px;
	border-bottom: 4px solid #ffb200;
	padding: 22px 64px;
	background-color: #fff;
}

.warning__wrapper--kits div::before {
	content: '';
	display: inline-block;
	width: 16px;
	height: 16px;
	margin-right: 17px;
	background-image: url('../img/warning.png');
	background-position: center;
	background-size: cover;
}

.warning.warning__wrapper--kits i {
	font-size: 16px;
	color: rgba(0, 0, 0, 0.2);
	align-self: flex-start;
	margin-left: 36px;
}

/* Administration page */

.admin-section {
	min-height: 250px;
}

.admin-section .admin-section__kits-list {
	margin-left: -60px !important;
	margin-right: -60px !important;
}
